import { SiteConfig } from "../../types";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "Levent School",
  pwaServiceName: "Levent",
  appLogoPath: "/partner/leventenglish/logo.png",
  appLogoHeight: "40px",
  landingLogoPath: "/partner/leventenglish/logo.png",
  landingLogoHeight: "40px",
  hubLogoPath: "/partner/leventenglish/logo.png",
  hubLogoHeight: "40px",
  favicon: "/partner/leventenglish/fav-icon.png",
  parentService: {
    name: "Video Corsi",
    link: "https://leventenglish.com",
  },
  pwaIcons: [
    {
      src: "/partner/leventenglish/pwa-icon.png",
      type: "image/png",
      sizes: "192x192",
    },
  ],
  overrideLanding: true,
  emailsSender: "info@lang-services.com",
  adminEmail: "leventschool@gmail.com",
  adminPhoneNumber: "+39 3474258840",
  whatsAppNumber: 393474258840,
  reviewLink: null,
  copyright: "Levent Schools International",
  showLanguageGuides: true,
  bioPrompt: `
    You are a tutor at Levent Schools.
  `,
  siteColors: {
    special: {
      desktopSidebar: "#fff",
      desktopSidebarBorderColor: "#ddd",
      desktopSidebarText: "#000",
      desktopSidebarTextHover: "#eee",
      chatAsideMenu: "#333",
      mobileNavBackgroundColor: "#333",
      mobileNavBackgroundContrastColor: "#ffffff",
      mobileNavBorderColor: "#333",
      speakInputIdle: "var(--chakra-colors-brand-tertiary-600)",
      speakInputRecording: "var(--chakra-colors-brand-tertiary-900)",
      //chatIconColor: "var(--chakra-colors-brand-primary-main)",
    },
  },
  colors: {
    primary: {
      main: "#228C22",
      mainContrast: "#fff",
      "50": "#f3f9f3",
      "100": "#cfe6cf",
      "200": "#a6d0a6",
      "300": "#72b572",
      "400": "#53a653",
      "500": "#299029",
      "600": "#1e7a1e",
      "700": "#186218",
      "800": "#145314",
      "900": "#0f3c0f",
    },

    secondary: {
      main: "#f5b417",
      mainContrast: "#000",
      "50": "#fef7e6",
      "100": "#fbdd97",
      "200": "#f6bd32",
      "300": "#d59c14",
      "400": "#be8c12",
      "500": "#a0760f",
      "600": "#87630d",
      "700": "#6d500a",
      "800": "#5c4309",
      "900": "#423106",
    },
    tertiary: {
      main: "#41246d",
      mainContrast: "#fff",
      "50": "#f8f7f9",
      "100": "#e3dee9",
      "200": "#cac2d6",
      "300": "#ada0c0",
      "400": "#9d8eb3",
      "500": "#8775a3",
      "600": "#756095",
      "700": "#614985",
      "800": "#543a7c",
      "900": "#40236b",
    },
    light: {
      main: "#f5f5f5",
      alt: "#f5f5f5",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#fafafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#adadad",
      "500": "#7f7f7f",
      "600": "#545454",
      "700": "#373737",
      "800": "#202020",
      "900": "#191919",
    },
    green: {
      "50": "#f9fcfa",
      "100": "#dfeee7",
      "200": "#bdddcd",
      "300": "#97c9b0",
      "400": "#70b492",
      "500": "#479f73",
      "600": "#218654",
      "700": "#196941",
      "800": "#155635",
      "900": "#11462c",
    },
    teal: {
      "50": "#f6fafa",
      "100": "#daecec",
      "200": "#badbdb",
      "300": "#94c7c7",
      "400": "#62adad",
      "500": "#369696",
      "600": "#1e7b7b",
      "700": "#176060",
      "800": "#135050",
      "900": "#104242",
    },
    cyan: {
      "50": "#f7fafb",
      "100": "#dfebee",
      "200": "#d0e3e7",
      "300": "#c0d9de",
      "400": "#90bcc5",
      "500": "#79aeb9",
      "600": "#5f9fac",
      "700": "#358696",
      "800": "#1f6e7f",
      "900": "#185662",
    },
    blue: {
      "50": "#f3f6f9",
      "100": "#d5dfe9",
      "200": "#b6c8d9",
      "300": "#95aec8",
      "400": "#7697b8",
      "500": "#5b82a9",
      "600": "#3f6d9b",
      "700": "#205385",
      "800": "#1b446d",
      "900": "#163759",
    },
    purple: {
      "50": "#f8f6fa",
      "100": "#e2dced",
      "200": "#ccc3e0",
      "300": "#ad9ecd",
      "400": "#9885bf",
      "500": "#7d65af",
      "600": "#6b4fa3",
      "700": "#593a99",
      "800": "#4a288f",
      "900": "#371b70",
    },
    pink: {
      "50": "#faf6f8",
      "100": "#eddde5",
      "200": "#dfc1d0",
      "300": "#cc9db4",
      "400": "#bf83a1",
      "500": "#ae6388",
      "600": "#a24b76",
      "700": "#932f61",
      "800": "#791d4b",
      "900": "#591637",
    },
    red: {
      "50": "#faf6f6",
      "100": "#eddcdc",
      "200": "#ddbebe",
      "300": "#ca9a9a",
      "400": "#c08585",
      "500": "#b16969",
      "600": "#a45050",
      "700": "#953333",
      "800": "#872121",
      "900": "#631818",
    },
    orange: {
      "50": "#fcfaf9",
      "100": "#f2ece6",
      "200": "#e3d7ca",
      "300": "#ceb8a2",
      "400": "#bc9d7e",
      "500": "#ab845d",
      "600": "#9a6c3e",
      "700": "#845220",
      "800": "#684119",
      "900": "#553515",
    },
    yellow: {
      "50": "#fefefd",
      "100": "#faf9f5",
      "200": "#efede1",
      "300": "#e4dfca",
      "400": "#d3ccab",
      "500": "#b4a86f",
      "600": "#968636",
      "700": "#77681d",
      "800": "#594e16",
      "900": "#4a4012",
    },
  },
  templates: [
    {
      type: "free-talk",
      titleKey: "dashboard.template.freeTalk.title",
      descriptionKey: "dashboard.template.freeTalk.description",
      backgroundColor: "brand.tertiary.main",
      buttonBackgroundColor: "#fff",
      borderColor: "#333",
      buttonTextColor: "#333",
      descriptionTextColor: "white",
    },
    {
      type: "role-play",
      titleKey: "dashboard.template.rolePlay.title",
      descriptionKey: "dashboard.template.rolePlay.description",
      backgroundColor: "brand.secondary.main",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "topic",
      titleKey: "dashboard.template.topics.title",
      descriptionKey: "dashboard.template.topics.description",
      backgroundColor: "#0cbcfc",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "quiz",
      titleKey: "dashboard.template.quiz.title",
      descriptionKey: "dashboard.template.quiz.description",
      backgroundColor: "#fc6c6c",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "case",
      titleKey: "dashboard.template.caseStudy.title",
      descriptionKey: "dashboard.template.caseStudy.description",
      backgroundColor: "brand.primary.main",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
  ],
};
