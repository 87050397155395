import { useCallback, useContext, useEffect, useMemo, useState, createContext } from "react";
import useSWR, { mutate } from "swr";
import { useRouter } from "next/router";

import { ApiError } from "../types/error";
import { fetchApi } from "../services/fetch-api";
import { UserFlashcardResponse } from "../pages/api/flashcard";
import { defaultSelectedWords, FLASHCARD_STORAGE_KEY } from "../types/constants";
import { Word } from "../types/flashcard";
import { UserFlashcardCollectionResponse } from "../pages/api/flashcard/[id]";
import { logger } from "../utils/logger";
import { LocalizationContext } from "./use-localization";
import { useSimpleToast } from "./use-simple-toast";

interface FlashcardContextProps {
  triggerRefetch: () => void;
}

export const FlashcardContext = createContext<FlashcardContextProps | undefined>(undefined);

export function useFlashcardStateRefetch() {
  const triggerRefetch = useCallback((id?: string) => {
    if (id) {
      mutate(`/flashcard/${encodeURIComponent(id)}`);
    } else {
      mutate((key: string) => key.startsWith("/flashcard/"));
    }
  }, []);

  return { triggerRefetch };
}

export function useFlashcard(id: string) {
  const { l } = useContext(LocalizationContext);
  const { toastSuccess, toastFail } = useSimpleToast();
  const router = useRouter();

  const { data, error, isLoading } = useSWR<UserFlashcardCollectionResponse, ApiError>(
    id ? `/flashcard/${encodeURIComponent(id)}` : null,
    fetchApi,
  );

  const flashcardCollection = useMemo(() => {
    return data?.collection;
  }, [data]);

  const deleteUserFlashcardCollection = async (flashcardId: string) => {
    if (!flashcardId) {
      logger.error("No item id provided");
      return;
    }

    const ok = confirm(l["components.saveFlashcard.deleteConfirm"]);
    if (ok) {
      try {
        const result = await fetchApi<UserFlashcardCollectionResponse>(
          `/flashcard/${encodeURIComponent(id)}`,
          {
            method: "DELETE",
          },
        );

        if (result.ok) {
          router.push("/app/wordbook/");
          toastSuccess(
            `"${flashcardCollection?.title}" ${l["components.saveFlashcard.delete.success"]}`,
          );
        }
      } catch (err: any) {
        logger.error("Error message: " + err?.message);
        toastFail(l["components.saveFlashcard.delete.failed"]);
      }
    }
  };

  return {
    flashcardCollection,
    flashcardCollectionError: error,
    flashcardCollectionIsLoading: isLoading,
    deleteUserFlashcardCollection,
  };
}

export function useFlashcards() {
  const [page, setPage] = useState(1);

  const { data, error, isLoading } = useSWR<UserFlashcardResponse, ApiError>(
    `/flashcard/?page=${page}`,
    fetchApi,
  );

  const flashcardPagination = useMemo(() => {
    const totalPages = data?.totalPages || 0;
    const itemsPerPage = data?.itemsPerPage || 0;
    const totalItems = data?.totalItems || 0;

    return {
      page,
      totalPages,
      itemsPerPage,
      totalItems,
    };
  }, [data, page]);

  const flashcards = useMemo(() => {
    return data?.collections || [];
  }, [data]);

  useEffect(() => {
    if (page > 1 && !isLoading && !flashcards?.length) {
      setPage(1);
    }
  }, [page, flashcards, isLoading]);

  return {
    flashcards,
    flashcardPagination,
    flashcardsError: error,
    flashcardsIsLoading: isLoading,
    setPage,
  };
}

export const getStoredWords = (): Word[] => {
  const storedWords = localStorage.getItem(FLASHCARD_STORAGE_KEY);
  return storedWords ? JSON.parse(storedWords) : defaultSelectedWords;
};

export function useManualSetupFlashCards() {
  const [selectedWords, setSelectedWords] = useState<Word[]>(getStoredWords);

  useEffect(() => {
    localStorage.setItem(FLASHCARD_STORAGE_KEY, JSON.stringify(selectedWords));
  }, [selectedWords]);

  return { selectedWords, setSelectedWords };
}
