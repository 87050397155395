import { SiteConfig } from "../../types";
import { cases } from "./cases";
import { invitationTemplate } from "./invitation-template";
import { quizzes } from "./quizzes";
import { roles } from "./roles";
import { swPrompt } from "./sw-prompt";
import { topics } from "./topics";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "SWAI",
  appLogoPath: "/partner/clubedealemao/logo-swai.png",
  appLogoHeight: "40px",
  landingLogoPath: "/partner/clubedealemao/logo-swai.png",
  landingLogoHeight: "40px",
  favicon: "/partner/clubedealemao/favicon.png",
  pwaIcons: [
    {
      src: "/partner/clubedealemao/app-icon.png",
      type: "image/png",
      sizes: "192x192",
    },
  ],
  overrideLanding: true,
  useTokenAuth: true,
  apiVersion: 1,
  authErrorPath: "https://swailogin.clubedealemao.com/logout",
  uiLanguage: "pt-BR",
  adminEmail: "contato@clubedealemao.com",
  adminPhoneNumber: "+55 35 9890-8169",
  whatsAppNumber: 553598908169,
  tocLink: "https://escola.spracheundwissen.com/termos/",
  privacyLink: "https://escola.spracheundwissen.com/termos/",
  // emailsSender: "no-reply@talkio.ai",
  // emailInvitationHtml: invitationTemplate,
  // emailInvitationSubject: "Você foi convidado para fazer parte da SWAI!",
  copyright: "Sprache&Wissen",
  practiceLanguageLock: "de-DE",
  userLanguageLock: "pt-BR",
  showLanguageGuides: true,
  hideProgressMenuLink: true,
  hideAsideMenuConversations: false,
  subscriptionDefaults: {
    currency: "usd",
    price: 300,
    characterLimit: 1_050_000,
    productId: "clubedealemao-1",
  },
  customContent: {
    baseLanguage: "pt-BR",
    roles: roles,
    topics: topics,
    cases: cases,
    quizzes: quizzes,
  },
  reviewLink: "https://depoimentos.spracheundwissen.com",
  bioPrompt: `
    You are a tutor at SWAI, part of Sprache&Wissen, a Brazilian online German school that offers comprehensive language courses and specialized programs.
    ${swPrompt}
  `,
  avatarExcludeList: [
    "Microsoft Server Speech Text to Speech Voice (en-US, RyanMultilingualNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, JennyMultilingualV2Neural)",
    "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyouNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, AnaNeural)",
    "Microsoft Server Speech Text to Speech Voice (de-DE, AmalaNeural)",
    "Microsoft Server Speech Text to Speech Voice (de-DE, BerndNeural)",
    "Microsoft Server Speech Text to Speech Voice (de-DE, GiselaNeural)",
    "Microsoft Server Speech Text to Speech Voice (de-DE, KasperNeural)",
    "Microsoft Server Speech Text to Speech Voice (de-DE, KlausNeural)",
    "Microsoft Server Speech Text to Speech Voice (de-DE, LouisaNeural)",
    "Microsoft Server Speech Text to Speech Voice (de-DE, MajaNeural)",
    "Microsoft Server Speech Text to Speech Voice (de-DE, RalfNeural)",
    "Microsoft Server Speech Text to Speech Voice (de-DE, TanjaNeural)",
  ],
  siteColors: {
    special: {
      desktopSidebar: "#fff",
      desktopSidebarBorderColor: "#e2e8f0",
      desktopSidebarText: "#000",
      desktopSidebarTextHover: "#eee",
      chatAsideMenu: "#37393b",
      speakInputIdle: "var(--chakra-colors-brand-secondary-700)",
      speakInputRecording: "var(--chakra-colors-brand-secondary-900)",
    },
  },
  colors: {
    primary: {
      main: "#F4D21A",
      mainContrast: "#000",
      "50": "#fdf8db",
      "100": "#f8e165",
      "200": "#e4c418",
      "300": "#bfa514",
      "400": "#ab9312",
      "500": "#F4D21A",
      "600": "#7a690d",
      "700": "#62540a",
      "800": "#534709",
      "900": "#3b3306",
    },
    secondary: {
      main: "#BD9C5C",
      mainContrast: "#000",
      "50": "#faf7f2",
      "100": "#eadfcb",
      "200": "#d7c39d",
      "300": "#c1a265",
      "400": "#ae9055",
      "500": "#937948",
      "600": "#7c663c",
      "700": "#645230",
      "800": "#544529",
      "900": "#3d321d",
    },
    tertiary: {
      main: "#ff5659",
      mainContrast: "#000",
      "50": "#fff5f5",
      "100": "#ffd7d7",
      "200": "#ffb1b3",
      "300": "#ff7f81",
      "400": "#ff5c5e",
      "500": "#db4a4c",
      "600": "#b93e41",
      "700": "#953234",
      "800": "#7e2b2c",
      "900": "#5c1f20",
    },
    light: {
      main: "#fff9ee",
      alt: "#fff9ee",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#f9fafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#acadad",
      "500": "#7e7f7f",
      "600": "#545455",
      "700": "#363737",
      "800": "#1f2021",
      "900": "#191a1a",
    },
    yellow: {
      "50": "#fffefa",
      "100": "#fef9e3",
      "200": "#fbeeab",
      "300": "#f8df64",
      "400": "#edca19",
      "500": "#c3a615",
      "600": "#9c8511",
      "700": "#7a680d",
      "800": "#5b4e0a",
      "900": "#4b4008",
    },
    green: {
      "50": "#f1fef8",
      "100": "#adfbd4",
      "200": "#1cf488",
      "300": "#17da78",
      "400": "#14bf69",
      "500": "#11a45b",
      "600": "#0e884b",
      "700": "#0b6a3a",
      "800": "#095730",
      "900": "#084727",
    },
    teal: {
      "50": "#e9fefe",
      "100": "#8efafa",
      "200": "#19ecec",
      "300": "#16d3d3",
      "400": "#13b3b3",
      "500": "#109898",
      "600": "#0d7c7c",
      "700": "#0a6060",
      "800": "#095050",
      "900": "#074242",
    },
    cyan: {
      "50": "#f0fcfe",
      "100": "#bff2fc",
      "200": "#a0ebfa",
      "300": "#79e3f9",
      "400": "#19c5e7",
      "500": "#17b5d4",
      "600": "#14a3bf",
      "700": "#11879e",
      "800": "#0e6f82",
      "900": "#0b5665",
    },
    blue: {
      "50": "#eff7fe",
      "100": "#c5e1fc",
      "200": "#9acafa",
      "300": "#69b0f8",
      "400": "#3796f5",
      "500": "#187fe6",
      "600": "#146ac0",
      "700": "#105193",
      "800": "#0d4379",
      "900": "#0b3763",
    },
    purple: {
      "50": "#f9f5ff",
      "100": "#e5d9fd",
      "200": "#d2bdfc",
      "300": "#b693fa",
      "400": "#a175f8",
      "500": "#854bf6",
      "600": "#6d28f5",
      "700": "#5717d6",
      "800": "#4813b1",
      "900": "#360e85",
    },
    pink: {
      "50": "#fff5fa",
      "100": "#fdd6ea",
      "200": "#fbb5d8",
      "300": "#f983be",
      "400": "#f75aa8",
      "500": "#e91981",
      "600": "#c8156f",
      "700": "#a4115b",
      "800": "#810e47",
      "900": "#600a35",
    },
    red: {
      "50": "#fff5f5",
      "100": "#fdd7d7",
      "200": "#fbb3b3",
      "300": "#f98383",
      "400": "#f76363",
      "500": "#f42020",
      "600": "#d11616",
      "700": "#a91212",
      "800": "#900f0f",
      "900": "#6a0b0b",
    },
    orange: {
      "50": "#fffaf5",
      "100": "#fdead7",
      "200": "#fbd1a8",
      "300": "#f7ac60",
      "400": "#f3861a",
      "500": "#d17416",
      "600": "#b06213",
      "700": "#8d4e0f",
      "800": "#6f3d0c",
      "900": "#5b320a",
    },
  },
};
