import { SiteConfig } from "../../types";
import { invitationTemplate } from "./invitation";
import { invitationTemplateFallback } from "./invitation-fallback-html";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "SaySo",
  pwaServiceName: "SaySo",
  appLogoPath: "/partner/say-so/app-logo.png",
  appLogoHeight: "40px",
  landingLogoPath: "/partner/say-so/landing-logo.png",
  landingLogoHeight: "60px",
  hubLogoPath: "/partner/say-so/app-logo.png",
  mobileLogoPath: "/partner/say-so/mobile-logo.png",
  favicon: "/partner/say-so/favicon.png",
  hubFixedPrice: true,
  showAccountUsage: true,
  pwaIcons: [
    {
      src: "/partner/say-so/app-icon-192.png",
      type: "image/png",
      sizes: "192x192",
    },
    {
      src: "/partner/say-so/app-icon.png",
      type: "image/png",
      sizes: "256x256",
    },
    {
      src: "/partner/say-so/app-icon.png",
      type: "image/png",
      sizes: "512x512",
    },
  ],
  overrideLanding: true,
  emailsSender: "info@lang-services.com",
  adminEmail: "info@say-so.pl",
  userLanguageLock: "pl-PL",
  uiLanguage: "pl-PL",
  reviewLink: null,
  adminPhoneNumber: "+48 780 734 550",
  tocLink: "https://www.say-so.pl/regulamin",
  privacyLink: "https://www.say-so.pl/polityka-prywatnosci",
  emailInvitationSubject: "Zostałeś zaproszony do SaySo",
  emailInvitationHtml: invitationTemplate,
  emailInvitationFallbackHtml: invitationTemplateFallback,
  emailInvitationFallbackSubject: "Masz zaproszenie!",
  copyright: "SaySo",
  showLanguageGuides: false,
  bioPrompt: `
    You are a tutor at SaySo, a language school in Poland.
    `,
  siteColors: {
    special: {
      desktopSidebar: "#786658",
      desktopSidebarBorderColor: "#e2e8f0",
      desktopSidebarText: "#fff",
      desktopSidebarTextHover: "#59493C",
      chatAsideMenu: "#59493C",
      mobileNavBackgroundColor: "#786658",
      mobileNavBackgroundContrastColor: "#ffffff",
      mobileNavBorderColor: "#786658",
      speakInputIdle: "#333",
      speakInputRecording: "var(--chakra-colors-brand-primary-900)",
      //chatIconColor: "var(--chakra-colors-brand-primary-main)",
    },
  },
  colors: {
    primary: {
      main: "#EB8113",
      mainContrast: "#fff",
      "50": "#fef6ee",
      "100": "#f9dbbc",
      "200": "#f4bb7f",
      "300": "#ed8f2e",
      "400": "#df7a12",
      "500": "#bc670f",
      "600": "#9f570d",
      "700": "#7f460a",
      "800": "#6c3b09",
      "900": "#4e2b06",
    },

    secondary: {
      main: "#EB8113",
      mainContrast: "#fff",
      "50": "#fef6ee",
      "100": "#f9dbbc",
      "200": "#f4bb7f",
      "300": "#ed8f2e",
      "400": "#df7a12",
      "500": "#bc670f",
      "600": "#9f570d",
      "700": "#7f460a",
      "800": "#6c3b09",
      "900": "#4e2b06",
    },
    tertiary: {
      main: "#3EB118",
      mainContrast: "#ffffff",
      "50": "#f2faef",
      "100": "#c9e9bf",
      "200": "#98d584",
      "300": "#57bb36",
      "400": "#3baa17",
      "500": "#328f13",
      "600": "#2a7910",
      "700": "#22610d",
      "800": "#1d520b",
      "900": "#153b08",
    },
    light: {
      main: "#f2f2f2",
      alt: "#f2f2f2",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#fafafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#adadad",
      "500": "#7f7f7f",
      "600": "#545454",
      "700": "#373737",
      "800": "#202020",
      "900": "#191919",
    },
    orange: {
      "50": "#fefaf5",
      "100": "#fcead9",
      "200": "#f8d2ac",
      "300": "#f2ae69",
      "400": "#ed8a27",
      "500": "#d37211",
      "600": "#b2600e",
      "700": "#8e4d0b",
      "800": "#703d09",
      "900": "#5c3207",
    },
    yellow: {
      "50": "#fff8d4",
      "100": "#FFD500",
      "200": "#e9c300",
      "300": "#c4a300",
      "400": "#af9200",
      "500": "#937b00",
      "600": "#7c6800",
      "700": "#645300",
      "800": "#544600",
      "900": "#3d3300",
    },
    green: {
      "50": "#f2fef8",
      "100": "#b7f9d8",
      "200": "#51f0a1",
      "300": "#12da76",
      "400": "#0fbf67",
      "500": "#0da459",
      "600": "#0b8849",
      "700": "#096a39",
      "800": "#07572f",
      "900": "#064727",
    },
    teal: {
      "50": "#ebfdfd",
      "100": "#a0f7f7",
      "200": "#1eecec",
      "300": "#11d3d3",
      "400": "#0eb3b3",
      "500": "#0c9898",
      "600": "#0a7c7c",
      "700": "#086060",
      "800": "#065050",
      "900": "#054242",
    },
    cyan: {
      "50": "#f1fcfe",
      "100": "#c4f1fa",
      "200": "#a7eaf8",
      "300": "#85e2f5",
      "400": "#13c5e9",
      "500": "#11b5d6",
      "600": "#10a3c1",
      "700": "#0d879f",
      "800": "#0b6f83",
      "900": "#085666",
    },
    blue: {
      "50": "#f0f7fe",
      "100": "#c7e1fa",
      "200": "#9dcaf7",
      "300": "#6eb0f3",
      "400": "#3f97ef",
      "500": "#137eea",
      "600": "#106ac4",
      "700": "#0c5196",
      "800": "#0a427b",
      "900": "#083664",
    },
    purple: {
      "50": "#f8f6fe",
      "100": "#e5d9fc",
      "200": "#d2bef9",
      "300": "#b594f6",
      "400": "#a177f3",
      "500": "#844ef0",
      "600": "#6e2eed",
      "700": "#5512db",
      "800": "#460fb5",
      "900": "#350b89",
    },
    pink: {
      "50": "#fef5fa",
      "100": "#fcd7e9",
      "200": "#f9b6d7",
      "300": "#f586bd",
      "400": "#f15fa8",
      "500": "#eb137f",
      "600": "#ca106d",
      "700": "#a50d59",
      "800": "#820b46",
      "900": "#610834",
    },
    red: {
      "50": "#fef5f5",
      "100": "#fcd8d8",
      "200": "#f9b4b4",
      "300": "#f58686",
      "400": "#f26767",
      "500": "#ed3030",
      "600": "#d21111",
      "700": "#ab0e0e",
      "800": "#910c0c",
      "900": "#6b0909",
    },
  },
  templates: [
    {
      type: "free-talk",
      titleKey: "dashboard.template.freeTalk.title",
      descriptionKey: "dashboard.template.freeTalk.description",
      backgroundColor: "brand.primary.main",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "role-play",
      titleKey: "dashboard.template.rolePlay.title",
      descriptionKey: "dashboard.template.rolePlay.description",
      backgroundColor: "brand.yellow.100",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "topic",
      titleKey: "dashboard.template.topics.title",
      descriptionKey: "dashboard.template.topics.description",
      backgroundColor: "brand.red.400",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "quiz",
      titleKey: "dashboard.template.quiz.title",
      descriptionKey: "dashboard.template.quiz.description",
      backgroundColor: "brand.green.300",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "case",
      titleKey: "dashboard.template.caseStudy.title",
      descriptionKey: "dashboard.template.caseStudy.description",
      backgroundColor: "brand.blue.400",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
  ],
};
