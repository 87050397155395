import { SiteConfig } from "../../types";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "Frenchami",
  appLogoPath: "/partner/frenchami/logo.png",
  appLogoHeight: "70px",
  landingLogoPath: "/partner/frenchami/logo.png",
  landingLogoHeight: "40px",
  favicon: "/partner/frenchami/icon.png",
  tocLink: "https://www.frenchami.com/terms-of-service",
  privacyLink: "https://www.frenchami.com/privacy-policy",
  pwaIcons: [
    {
      src: "/partner/frenchami/icon.png",
      type: "image/png",
      sizes: "192x192",
    },
  ],
  overrideLanding: true,
  useTokenAuth: false,
  reviewLink: null,
  hubFixedPrice: true,
  hubFacilitatorCanManage: true,
  showAccountUsage: true,
  /// uiLanguage: "pt-BR",
  adminEmail: "info@frenchami.com",
  // adminPhoneNumber: "(0030)6908250297",
  emailsSender: "info@frenchami.com",
  emailInvitationSubject: "You have been invited to Frenchami!",
  emailInvitationText: `
  You have been invited to Frenchami! 🎉
  
  We have created a user for you with the following details:
  Email: {email}
  Password: {password}

  Follow this link to accept the invitation:
  {link}
  `,
  copyright: "Frenchami",
  practiceLanguageLock: "fr-CA",
  // userLanguageLock: "id-ID",
  showLanguageGuides: true,
  subscriptionDefaults: {
    currency: "eur",
    price: 500,
    characterLimit: 1_050_000,
    productId: "frenchami-1",
  },
  bioPrompt: `
    You are a language tutor working for Frenchami. 

    About the school:    
    At Frenchami, we believe that learning French should be an enjoyable and interactive experience. Our user-friendly website is designed to help you learn French at your own pace, with a range of features and resources to make learning accessible, engaging, and effective. Our mission is to provide a comprehensive and interactive learning experience that enables individuals to achieve their French language goals.
  `,
  avatarExcludeList: [
    "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyouNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, AnaNeural)",
  ],
  siteColors: {
    special: {
      desktopSidebar: "#fff",
      desktopSidebarBorderColor: "#eee",
      desktopSidebarText: "#000",
      desktopSidebarTextHover: "#b8d0ff",
      chatAsideMenu: "#333",
      speakInputIdle: "var(--chakra-colors-brand-secondary-700)",
      speakInputRecording: "var(--chakra-colors-brand-secondary-900)",
    },
  },
  colors: {
    light: {
      main: "#ececec",
      alt: "#ececec",
    },
    primary: {
      main: "#b8d0ff",
      mainContrast: "#000",
      "50": "#f4f7ff",
      "100": "#d1e1ff",
      "200": "#afc6f3",
      "300": "#93a6cc",
      "400": "#8495b6",
      "500": "#b8d0ff",
      "600": "#5d6a82",
      "700": "#4b5568",
      "800": "#3f4858",
      "900": "#2e343f",
    },
    secondary: {
      main: "#82ac9e",
      mainContrast: "#000",
      "50": "#f4f8f7",
      "100": "#d5e3df",
      "200": "#b1cbc3",
      "300": "#85aea1",
      "400": "#769c8f",
      "500": "#638479",
      "600": "#546f66",
      "700": "#435952",
      "800": "#394b45",
      "900": "#293632",
    },

    tertiary: {
      main: "#82ac9e",
      mainContrast: "#000",
      "50": "#f4f8f7",
      "100": "#d5e3df",
      "200": "#b1cbc3",
      "300": "#85aea1",
      "400": "#769c8f",
      "500": "#638479",
      "600": "#546f66",
      "700": "#435952",
      "800": "#394b45",
      "900": "#293632",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#f9fafa",
      "100": "#f1f1f2",
      "200": "#e6e7e9",
      "300": "#d2d4d7",
      "400": "#a9adb2",
      "500": "#797f88",
      "600": "#4d5560",
      "700": "#2e3744",
      "800": "#19202b",
      "900": "#141a23",
    },
    purple: {
      "50": "#f8f5ff",
      "100": "#e5d9ff",
      "200": "#d1bdff",
      "300": "#af9cd8",
      "400": "#9686ba",
      "500": "#786b95",
      "600": "#64597c",
      "700": "#524965",
      "800": "#433c53",
      "900": "#312c3d",
    },
    pink: {
      "50": "#fff5fa",
      "100": "#ffd6eb",
      "200": "#fbb5d9",
      "300": "#d499b8",
      "400": "#b986a1",
      "500": "#996e84",
      "600": "#835e71",
      "700": "#6a4c5c",
      "800": "#523b47",
      "900": "#3c2b34",
    },
    orange: {
      "50": "#fffaf5",
      "100": "#ffe9d6",
      "200": "#f6d2b1",
      "300": "#d5b69a",
      "400": "#b89d85",
      "500": "#9e8772",
      "600": "#867260",
      "700": "#6a5b4d",
      "800": "#54473c",
      "900": "#453b31",
    },
    yellow: {
      "50": "#fffefa",
      "100": "#fff9df",
      "200": "#faedb4",
      "300": "#ece0aa",
      "400": "#d7cc9b",
      "500": "#b1a880",
      "600": "#8e8666",
      "700": "#6e6950",
      "800": "#534e3c",
      "900": "#444131",
    },
    green: {
      "50": "#effff7",
      "100": "#b4fad5",
      "200": "#a4e3c2",
      "300": "#92caad",
      "400": "#80b197",
      "500": "#6e9882",
      "600": "#5b7e6b",
      "700": "#466253",
      "800": "#3a5044",
      "900": "#2f4138",
    },
    teal: {
      "50": "#e4ffff",
      "100": "#b0f4f3",
      "200": "#a2e0df",
      "300": "#90c8c7",
      "400": "#7aaaa9",
      "500": "#689090",
      "600": "#547574",
      "700": "#415b5a",
      "800": "#374c4b",
      "900": "#2d3e3e",
    },
    cyan: {
      "50": "#eefcff",
      "100": "#b7f3fe",
      "200": "#b0e9f4",
      "300": "#a7dee8",
      "400": "#8ebcc5",
      "500": "#82adb5",
      "600": "#769ca3",
      "700": "#618187",
      "800": "#506a6e",
      "900": "#3e5255",
    },
    blue: {
      "50": "#eef7ff",
      "100": "#c1e1ff",
      "200": "#a8c9e8",
      "300": "#92afca",
      "400": "#7d96ae",
      "500": "#6c8195",
      "600": "#5a6c7d",
      "700": "#45525f",
      "800": "#38434e",
      "900": "#2e373f",
    },
  },
};
