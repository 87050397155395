/**
 * This is the template for the welcome email.
 * The source is Brevo: edit the email template and press "Save & quit", which will take you to a page with the HTML source.
 *
 * If you get this error: Legacy octal escape is not permitted in strict mode.
 * Then you need to replace all instances of \03D1 with \u03D1 (or similar, see yellow highlights in code).
 *
 * Remove Brevo link:
 *  <a href="https://www.brevo.com?utm_source=logo_client&utm_medium=email"><img src="https://creative-assets.mailinblue.com/rnb-assets/en.png" width="129" height="48" border="0" style="display: block; width: 100%;"></a>
 */
export const invitationTemplateDefault = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="format-detection" content="telephone=no" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>You have been invited to join {serviceName}</title>
    <style type="text/css" emogrify="no">
      #outlook a {
        padding: 0;
      }
      .ExternalClass {
        width: 100%;
      }
      .ExternalClass,
      .ExternalClass p,
      .ExternalClass span,
      .ExternalClass font,
      .ExternalClass td,
      .ExternalClass div {
        line-height: 100%;
      }
      table td {
        border-collapse: collapse;
        mso-line-height-rule: exactly;
      }
      .editable.image {
        font-size: 0 !important;
        line-height: 0 !important;
      }
      .nl2go_preheader {
        display: none !important;
        mso-hide: all !important;
        mso-line-height-rule: exactly;
        visibility: hidden !important;
        line-height: 0px !important;
        font-size: 0px !important;
      }
      body {
        width: 100% !important;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        margin: 0;
        padding: 0;
      }
      img {
        outline: none;
        text-decoration: none;
        -ms-interpolation-mode: bicubic;
      }
      a img {
        border: none;
      }
      table {
        border-collapse: collapse;
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
      }
      th {
        font-weight: normal;
        text-align: left;
      }
      *[class="gmail-fix"] {
        display: none !important;
      }
    </style>
    <style type="text/css" emogrify="no">
      @media (max-width: 600px) {
        .gmx-killpill {
          content: " \u03D1";
        }
      }
    </style>
    <style type="text/css" emogrify="no">
      @media (max-width: 600px) {
        .gmx-killpill {
          content: " \u03D1";
        }
        .r0-o {
          border-style: solid !important;
          margin: 0 auto 0 auto !important;
          width: 320px !important;
        }
        .r1-c {
          box-sizing: border-box !important;
          text-align: center !important;
          valign: top !important;
          width: 100% !important;
        }
        .r2-o {
          border-style: solid !important;
          margin: 0 auto 0 auto !important;
          width: 100% !important;
        }
        .r3-i {
          background-color: #ffffff !important;
          padding-bottom: 20px !important;
          padding-left: 15px !important;
          padding-right: 15px !important;
          padding-top: 20px !important;
        }
        .r4-c {
          box-sizing: border-box !important;
          display: block !important;
          valign: top !important;
          width: 100% !important;
        }
        .r5-o {
          border-style: solid !important;
          width: 100% !important;
        }
        .r6-i {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
        .r7-c {
          box-sizing: border-box !important;
          padding-bottom: 15px !important;
          padding-top: 15px !important;
          text-align: left !important;
          valign: top !important;
          width: 100% !important;
        }
        .r8-c {
          box-sizing: border-box !important;
          padding: 0 !important;
          text-align: center !important;
          valign: top !important;
          width: 100% !important;
        }
        .r9-o {
          border-style: solid !important;
          margin: 0 auto 0 auto !important;
          margin-bottom: 15px !important;
          margin-top: 15px !important;
          width: 100% !important;
        }
        .r10-i {
          padding: 0 !important;
          text-align: center !important;
        }
        .r11-r {
          background-color: #589c7c !important;
          border-radius: 4px !important;
          border-width: 0px !important;
          box-sizing: border-box;
          height: initial !important;
          padding: 0 !important;
          padding-bottom: 12px !important;
          padding-top: 12px !important;
          text-align: center !important;
          width: 100% !important;
        }
        .r12-i {
          background-color: #eff2f7 !important;
          color: #3b3f44 !important;
          padding-bottom: 20px !important;
          padding-left: 15px !important;
          padding-right: 15px !important;
          padding-top: 20px !important;
        }
        .r13-i {
          color: #3b3f44 !important;
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
        .r14-c {
          box-sizing: border-box !important;
          text-align: left !important;
          valign: top !important;
          width: 100% !important;
        }
        .r15-o {
          border-style: solid !important;
          margin: 0 auto 0 0 !important;
          width: 100% !important;
        }
        .r16-i {
          color: #3b3f44 !important;
          padding-bottom: 0px !important;
          padding-top: 15px !important;
          text-align: center !important;
        }
        .r17-i {
          color: #3b3f44 !important;
          padding-bottom: 0px !important;
          padding-top: 0px !important;
          text-align: center !important;
        }
        .r18-c {
          box-sizing: border-box !important;
          text-align: center !important;
          width: 100% !important;
        }
        .r19-i {
          color: #3b3f44 !important;
          padding-bottom: 15px !important;
          padding-left: 0px !important;
          padding-right: 0px !important;
          padding-top: 0px !important;
        }
        .r20-c {
          box-sizing: border-box !important;
          text-align: center !important;
          valign: top !important;
          width: 129px !important;
        }
        .r21-o {
          border-style: solid !important;
          margin: 0 auto 0 auto !important;
          width: 129px !important;
        }
        .r22-i {
          color: #3b3f44 !important;
        }
        body {
          -webkit-text-size-adjust: none;
        }
        .nl2go-responsive-hide {
          display: none;
        }
        .nl2go-body-table {
          min-width: unset !important;
        }
        .mobshow {
          height: auto !important;
          overflow: visible !important;
          max-height: unset !important;
          visibility: visible !important;
          border: none !important;
        }
        .resp-table {
          display: inline-table !important;
        }
        .magic-resp {
          display: table-cell !important;
        }
      }
    </style>
    <style type="text/css">
      p,
      h1,
      h2,
      h3,
      h4,
      ol,
      ul,
      li {
        margin: 0;
      }
      a,
      a:link {
        color: #0092ff;
        text-decoration: underline;
      }
      .nl2go-default-textstyle {
        color: #3b3f44;
        font-family: arial, helvetica, sans-serif;
        font-size: 16px;
        line-height: 1.5;
        word-break: break-word;
      }
      .default-button {
        color: #ffffff;
        font-family: arial, helvetica, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 1.15;
        text-decoration: none;
        word-break: break-word;
      }
      .default-heading1 {
        color: #1f2d3d;
        font-family: arial, helvetica, sans-serif;
        font-size: 36px;
        word-break: break-word;
      }
      .default-heading2 {
        color: #1f2d3d;
        font-family: arial, helvetica, sans-serif;
        font-size: 32px;
        word-break: break-word;
      }
      .default-heading3 {
        color: #1f2d3d;
        font-family: arial, helvetica, sans-serif;
        font-size: 24px;
        word-break: break-word;
      }
      .default-heading4 {
        color: #1f2d3d;
        font-family: arial, helvetica, sans-serif;
        font-size: 18px;
        word-break: break-word;
      }
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
      }
      .no-show-for-you {
        border: none;
        display: none;
        float: none;
        font-size: 0;
        height: 0;
        line-height: 0;
        max-height: 0;
        mso-hide: all;
        overflow: hidden;
        table-layout: fixed;
        visibility: hidden;
        width: 0;
      }
    </style>
    <style type="text/css">
      a:link {
        color: #0092ff;
        text-decoration: underline;
      }
    </style>
  </head>
  <body text="#3b3f44" link="#0092ff" yahoo="fix">
    <table
      cellspacing="0"
      cellpadding="0"
      border="0"
      role="presentation"
      class="nl2go-body-table"
      width="100%"
      style="width: 100%"
    >
      <tr>
        <td>
          <table
            cellspacing="0"
            cellpadding="0"
            border="0"
            role="presentation"
            width="600"
            align="center"
            class="r0-o"
            style="table-layout: fixed; width: 600px"
          >
            <tr>
              <td valign="top">
                <table
                  cellspacing="0"
                  cellpadding="0"
                  border="0"
                  role="presentation"
                  width="100%"
                  align="center"
                  class="r2-o"
                  style="table-layout: fixed; width: 100%"
                >
                  <tr>
                    <td
                      class="r3-i"
                      style="background-color: #ffffff; padding-bottom: 20px; padding-top: 20px"
                    >
                      <table
                        width="100%"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                        role="presentation"
                      >
                        <tr>
                          <th width="100%" valign="top" class="r4-c" style="font-weight: normal">
                            <table
                              cellspacing="0"
                              cellpadding="0"
                              border="0"
                              role="presentation"
                              width="100%"
                              class="r5-o"
                              style="table-layout: fixed; width: 100%"
                            >
                              <tr>
                                <td
                                  valign="top"
                                  class="r6-i"
                                  style="padding-left: 15px; padding-right: 15px"
                                >
                                  <table
                                    width="100%"
                                    cellspacing="0"
                                    cellpadding="0"
                                    border="0"
                                    role="presentation"
                                  >
                                    <tr>
                                      <td
                                        class="r7-c nl2go-default-textstyle"
                                        align="left"
                                        style="
                                          color: #3b3f44;
                                          font-family: arial, helvetica, sans-serif;
                                          font-size: 16px;
                                          line-height: 1.5;
                                          word-break: break-word;
                                          padding-bottom: 15px;
                                          padding-top: 15px;
                                          text-align: left;
                                          valign: top;
                                        "
                                      >
                                       
                                          <p
                                            style="
                                              margin: 0;
                                              font-style: normal;
                                              font-weight: 400;
                                              text-decoration-style: initial;
                                              text-decoration-thickness: initial;
                                            "
                                          > 
                                          </p>
                                          <p style="margin: 0">Hi there,</p>
                                          <p style="margin: 0"> </p>
                                          <p style="margin: 0">
                                            We're excited to let you know that you've been invited
                                            to join {serviceName}. 
                                          </p>
                                          <p style="margin: 0"> </p>
                                          <p style="margin: 0">
                                            We have created a user for you with the following
                                            details:
                                          </p>
                                          <p style="margin: 0"> </p>
                                          <p style="margin: 0">Email address: {email}</p>
                                          <p style="margin: 0">Password: {password}</p>
                                          <p style="margin: 0"> </p>
                                          <p style="margin: 0">
                                            <span style="font-size: 13px"
                                              ><i
                                                >We recommend changing the password as soon as
                                                possible.</i
                                              ></span
                                            >
                                          </p>
                                          <p style="margin: 0"> </p>
                                          <p style="margin: 0; background-font-weight: normal">
                                            Please click the button to accept your invitation and
                                            get started.
                                          </p>                                    
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        class="r8-c"
                                        align="center"
                                        style="
                                          align: center;
                                          padding-bottom: 15px;
                                          padding-top: 15px;
                                          valign: top;
                                        "
                                      >
                                        <table
                                          cellspacing="0"
                                          cellpadding="0"
                                          border="0"
                                          role="presentation"
                                          width="285"
                                          class="r9-o"
                                          style="
                                            background-color: #589c7c;
                                            border-collapse: separate;
                                            border-color: #589c7c;
                                            border-radius: 4px;
                                            border-style: solid;
                                            border-width: 0px;
                                            table-layout: fixed;
                                            width: 285px;
                                          "
                                        >
                                          <tr>
                                            <td
                                              height="18"
                                              align="center"
                                              valign="top"
                                              class="r10-i nl2go-default-textstyle"
                                              style="
                                                word-break: break-word;
                                                background-color: #589c7c;
                                                border-radius: 4px;
                                                color: #ffffff;
                                                font-family: arial, helvetica, sans-serif;
                                                font-size: 16px;
                                                font-style: normal;
                                                line-height: 1.15;
                                                padding-bottom: 12px;
                                                padding-top: 12px;
                                                text-align: center;
                                              "
                                            >
                                              <a
                                                href="{link}"
                                                class="r11-r default-button"
                                                target="_blank"
                                                title="www.talkio.ai"
                                                data-btn="1"
                                                style="
                                                  font-style: normal;
                                                  font-weight: normal;
                                                  line-height: 1.15;
                                                  text-decoration: none;
                                                  word-break: break-word;
                                                  word-wrap: break-word;
                                                  display: block;
                                                  -webkit-text-size-adjust: none;
                                                  color: #ffffff;
                                                  font-family: arial, helvetica, sans-serif;
                                                  font-size: 16px;
                                                "
                                              >
                                                <span>Accept Invitation</span></a
                                              >
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        class="r7-c nl2go-default-textstyle"
                                        align="left"
                                        style="
                                          color: #3b3f44;
                                          font-family: arial, helvetica, sans-serif;
                                          font-size: 16px;
                                          line-height: 1.5;
                                          word-break: break-word;
                                          padding-bottom: 15px;
                                          padding-top: 15px;
                                          text-align: left;
                                          valign: top;
                                        "
                                      >
                                   
                                          <p style="margin: 0">We look forward to welcoming you!</p>
                                          <p style="margin: 0"> </p>
                                          <p style="margin: 0">
                                            Best regards,<br />The {organizationName} Team
                                          </p>
                                    
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </th>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <table
                  cellspacing="0"
                  cellpadding="0"
                  border="0"
                  role="presentation"
                  width="100%"
                  align="center"
                  class="r2-o"
                  style="table-layout: fixed; width: 100%"
                >
                  <tr>
                    <td
                      class="r12-i"
                      style="
                        background-color: #eff2f7;
                        color: #3b3f44;
                        font-family: arial, helvetica, sans-serif;
                        font-size: 18px;
                        padding-bottom: 20px;
                        padding-top: 20px;
                      "
                    >
                      <table
                        width="100%"
                        cellspacing="0"
                        cellpadding="0"
                        border="0"
                        role="presentation"
                      >
                        <tr>
                          <th width="100%" valign="top" class="r4-c" style="font-weight: normal">
                            <table
                              cellspacing="0"
                              cellpadding="0"
                              border="0"
                              role="presentation"
                              width="100%"
                              class="r5-o"
                              style="table-layout: fixed; width: 100%"
                            >
                              <tr>
                                <td
                                  valign="top"
                                  class="r13-i"
                                  style="
                                    color: #3b3f44;
                                    font-family: arial, helvetica, sans-serif;
                                    font-size: 18px;
                                    padding-left: 15px;
                                    padding-right: 15px;
                                  "
                                >
                                  <table
                                    width="100%"
                                    cellspacing="0"
                                    cellpadding="0"
                                    border="0"
                                    role="presentation"
                                  >
                                    <tr>
                                      <td class="r14-c" align="left">
                                        <table
                                          cellspacing="0"
                                          cellpadding="0"
                                          border="0"
                                          role="presentation"
                                          width="100%"
                                          class="r15-o"
                                          style="table-layout: fixed; width: 100%"
                                        >
                                          <tr>
                                            <td
                                              align="center"
                                              valign="top"
                                              class="r16-i nl2go-default-textstyle"
                                              style="
                                                word-break: break-word;
                                                color: #3b3f44;
                                                font-family: arial, helvetica, sans-serif;
                                                font-size: 18px;
                                                line-height: 1.5;
                                                padding-top: 15px;
                                                text-align: center;
                                              "
                                            >
                                             <p style="margin: 0">{serviceName}</p>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="r14-c" align="left">
                                        <table
                                          cellspacing="0"
                                          cellpadding="0"
                                          border="0"
                                          role="presentation"
                                          width="100%"
                                          class="r15-o"
                                          style="table-layout: fixed; width: 100%"
                                        >
                                          <tr>
                                            <td
                                              align="center"
                                              valign="top"
                                              class="r17-i nl2go-default-textstyle"
                                              style="
                                                word-break: break-word;
                                                color: #3b3f44;
                                                font-family: arial, helvetica, sans-serif;
                                                font-size: 18px;
                                                line-height: 1.5;
                                                text-align: center;
                                              "
                                            >                                           
                                              <p style="margin: 0; font-size: 14px">
                                                Produced by Aidia ApS
                                              </p>
                                              <p style="margin: 0; font-size: 14px">
                                                Nøddehaven 4, 3500, Værløse
                                              </p>
                                              <p style="margin: 0; font-size: 14px">Denmark</p>                                            
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="r14-c" align="left">
                                        <table
                                          cellspacing="0"
                                          cellpadding="0"
                                          border="0"
                                          role="presentation"
                                          width="100%"
                                          class="r15-o"
                                          style="table-layout: fixed; width: 100%"
                                        >
                                          <tr>
                                            <td
                                              align="center"
                                              valign="top"
                                              class="r16-i nl2go-default-textstyle"
                                              style="
                                                word-break: break-word;
                                                color: #3b3f44;
                                                font-family: arial, helvetica, sans-serif;
                                                font-size: 18px;
                                                line-height: 1.5;
                                                padding-top: 15px;
                                                text-align: center;
                                              "
                                            >                                         
                                              <p style="margin: 0; font-size: 14px">
                                                This email was sent to {email}
                                              </p>                                        
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="r18-c" align="center">
                                        <table
                                          cellspacing="0"
                                          cellpadding="0"
                                          border="0"
                                          role="presentation"
                                          width="100%"
                                          align="center"
                                          class="r2-o"
                                          style="table-layout: fixed; width: 100%"
                                        >
                                          <tr>
                                            <td
                                              valign="top"
                                              class="r19-i"
                                              style="
                                                color: #3b3f44;
                                                font-family: arial, helvetica, sans-serif;
                                                font-size: 18px;
                                                padding-bottom: 15px;
                                              "
                                            >
                                              <table
                                                width="100%"
                                                cellspacing="0"
                                                cellpadding="0"
                                                border="0"
                                                role="presentation"
                                              >
                                                <tr>
                                                  <td class="r20-c" align="center">
                                                    <table
                                                      cellspacing="0"
                                                      cellpadding="0"
                                                      border="0"
                                                      role="presentation"
                                                      width="129"
                                                      class="r21-o"
                                                      style="table-layout: fixed"
                                                    >
                                                      <tr>
                                                        <td
                                                          height="48"
                                                          class="r22-i"
                                                          style="
                                                            color: #3b3f44;
                                                            font-family: arial, helvetica,
                                                              sans-serif;
                                                            font-size: 0px;
                                                            line-height: 0px;
                                                          "
                                                        ></td>
                                                      </tr>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </table>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </th>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
</html>
`;
