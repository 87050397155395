import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react";
import router from "next/router";
import { useUser } from "../../hooks/use-user";
import { LandingLocale } from "../../localization/landing";
import LanguageSelectorLandingDynamic from "../language-selector-landing-dynamic";
import { signOut } from "next-auth/react";
import { appSignInRedirect } from "../../types/constants";
import { useContext } from 'react';
import { IpLocationContext } from '../../hooks/use-location-detector';

export default function FooterLanding(props: {
  l: LandingLocale;
  baseLangCode: string;
}) {
  const user = useUser(false);
  const l = props.l;
  const ipLocation = useContext(IpLocationContext)

  return (
    <Box id="footer" as="footer" backgroundColor="brand.gray.700" color="white" width="100%">
      <Flex
        justifyContent={"space-evenly"}
        py={12}
        px={10}
        flexDir={{ base: "column", md: "row" }}
        lineHeight={2}
        flex="1"
      >
        <Flex
          width="100%"
          textAlign={{ base: "center", md: "left" }}
          mb={10}
          justifyContent={{ base: "center", md: "flex-start" }}
        >
          <Flex flexDirection={"column"}>
            <Heading fontSize="md" width="100%">
              {l["layout.footer.pages"]}
            </Heading>
            <Link flex="1" href="/hub">
              {l["layout.footer.learningHub"]}
            </Link>
            <Link flex="1" href="/blog">
              {l["layout.footer.blog"]}
            </Link>
            <Link flex="1" href="/about">
              {l["layout.footer.about"]}
            </Link>
            <Link
              hidden={ipLocation?.countryCode === "KR"}
              flex="1"
              href="/partners/affiliates"
            >
              {l["layout.footer.affiliateProgram"]}
            </Link>
            <Link flex="1" href="/partners/schools">
              {l["layout.footer.schools"]}
            </Link>
            <Link flex="1" href="/partners/business">
              {l["layout.footer.teams"]}
            </Link>
            <Link flex="1" href="/guide/beginner-intro">
              {l["layout.footer.languageGuide"]}
            </Link>
            <Link flex="1" href="/legal/terms">
              {l["layout.footer.termsConditions"]}
            </Link>
            <Link flex="1" href="/legal/privacy">
              {l["layout.footer.privacyPolicy"]}
            </Link>

            {user.sessionUser ? (
              <Link flex="1" onClick={() => signOut({ callbackUrl: "/" })}>
                {l["layout.footer.logout"]}
              </Link>
            ) : (
              <Link flex="1" onClick={() => router.push(appSignInRedirect)}>
                {l["layout.footer.login"]}
              </Link>
            )}
          </Flex>
        </Flex>
        <Flex textAlign={"center"} width="100%" flexDir={"column"} mb={10}>
          <Heading textAlign={"center"} fontSize="md" width="100%" mb="5">
            {l["layout.footer.blogPosts"]}
          </Heading>

          <Link mb={3} fontSize="sm" href="/blog/posts/interactive-guide" lineHeight={1.2}>
            {l["layout.footer.blogPost1"]}
          </Link>

          <Link mb={3} fontSize="sm" href="/blog/posts/talkio-gpts" lineHeight={1.2}>
            {l["layout.footer.blogPost2"]}
          </Link>

          <Link
            mb={3}
            fontSize="sm"
            href="/blog/posts/prepare-for-ielts-with-talkio"
            lineHeight={1.2}
          >
            {l["layout.footer.blogPost3"]}
          </Link>
          <Link
            mb={3}
            fontSize="sm"
            href="/blog/posts/three-effective-ways-of-practice"
            lineHeight={1.2}
          >
            {l["layout.footer.blogPost4"]}
          </Link>
          <Link mb={3} fontSize="sm" href="/blog/posts/acing-your-english-test" lineHeight={1.2}>
            {l["layout.footer.blogPost5"]}
          </Link>

          <Link
            mb={3}
            fontSize="sm"
            href="/blog/posts/prepare-for-toefl-with-talkio"
            lineHeight={1.2}
          >
            {l["layout.footer.blogPost6"]}
          </Link>
        </Flex>

        <Flex width="100%" flexDirection={"column"} textAlign={{ base: "center", md: "right" }}>
          <Heading fontSize="md" width="100%" mb="5">
            {l["layout.footer.contact"]}
          </Heading>
          <Link fontSize="xs" lineHeight={1.4} mb={2} href="mailto:hello@talkio.ai">
            hello@talkio.ai
          </Link>

          <Text mb={2} fontSize="xs" lineHeight={1.4}>
            Aidia ApS <br />
            Nøddehaven 4 <br />
            3500 Værløse <br />
            Denmark
          </Text>

          <Text lineHeight={1.4} fontSize="xs">
            {process.env.NEXT_PUBLIC_URL}
          </Text>

          {props.baseLangCode && (
            <Box color="black">
              <LanguageSelectorLandingDynamic defaultBaseLangCode={props.baseLangCode} />
            </Box>
          )}
        </Flex>
      </Flex>
      <Box width="100%" textAlign="center" pb={5}>
        <Text fontSize={"sm"}>
          ©Aidia ApS {new Date().getFullYear()}. {l["layout.footer.rights"]}
        </Text>
        <Link
          mt={2}
          fontSize={"xs"}
          rel="noopener noreferrer nofollow"
          href="https://www.freepik.com/free-vector/chatbot-voice-controlled-virtual-assistant-abstract-concept-illustration_12290856.htm#query=robot%20voice%20chat&position=2&from_view=search&track=ais"
        >
          {l["layout.footer.illustrationCredits"]}
        </Link>
      </Box>
    </Box>
  );
}
