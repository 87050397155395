import { Box, Flex, HStack, PinInput, PinInputField, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";

export default function BetaPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const password = "MzUwMDI0"; // 'postnummer + år'
  const key = "talkio-pw";

  const turnOff = () => {
    setShowPassword(false);
    window.sessionStorage.setItem(key, password);
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      if (process.env.NEXT_PUBLIC_ENABLE_SITE_PASSWORD === "true") {
        setShowPassword(true);
      }

      if (window.sessionStorage.getItem(key) === password) {
        turnOff();
      }
    }
  }, []);

  const onComplete = (pw: string) => {
    if (pw === atob(password)) {
      turnOff();
    }
  };

  return !showPassword ? (
    <></>
  ) : (
    <Flex
      pos="fixed"
      h="100vh"
      w="100vw"
      bg="white"
      zIndex={10000}
      justifyContent="center"
      alignItems={"center"}
    >
      <Flex
        data-tid="beta-password"
        left={0}
        top={0}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Text mb={5}>Please provide the password</Text>
        <HStack justifyContent="center">
          <PinInput onComplete={onComplete}>
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
          </PinInput>
        </HStack>
      </Flex>
    </Flex>
  );
}
