import { SiteConfig } from "../../types";
// import { invitationTemplate } from "./invitation";
// import { invitationTemplateFallback } from "./invitation-default-fallback-html";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "EM Language Academy",
  pwaServiceName: "EM Language Academy",
  appLogoPath: "/partner/emacademy/logo.webp",
  appLogoHeight: "40px",
  landingLogoPath: "/partner/emacademy/logo.webp",
  landingLogoHeight: "60px",
  hubLogoPath: "/partner/emacademy/logo.webp",
  hubLogoHeight: "60px",
  favicon: "/partner/emacademy/fav-icon.png",
  pwaIcons: [
    {
      src: "/partner/emacademy/pwa-icon.png",
      type: "image/png",
      sizes: "192x192",
    },
  ],
  overrideLanding: true,
  emailsSender: "info@lang-services.com",
  adminEmail: "info@emacademy.eu",
  adminPhoneNumber: "37061585050",
  whatsAppNumber: 37061585050,
  reviewLink: null,
  emailInvitationSubject: "Sveiki atvykę į EM SmartLearn",
  // emailInvitationHtml: invitationTemplate,
  // emailInvitationFallbackHtml: invitationTemplateFallback,
  emailInvitationFallbackSubject: "Sveiki atvykę į EM SmartLearn",
  copyright: "EM Language Academy",
  showLanguageGuides: true,
  bioPrompt: `
    You are a tutor at EM Language Academy in Lithuania.
  `,
  siteColors: {
    special: {
      desktopSidebar: "#fff",
      desktopSidebarBorderColor: "#ddd",
      desktopSidebarText: "#000",
      desktopSidebarTextHover: "#eee",
      chatAsideMenu: "#333",
      mobileNavBackgroundColor: "#333",
      mobileNavBackgroundContrastColor: "#ffffff",
      mobileNavBorderColor: "#333",
      speakInputIdle: "var(--chakra-colors-brand-primary-700)",
      speakInputRecording: "var(--chakra-colors-brand-primary-900)",
      //chatIconColor: "var(--chakra-colors-brand-primary-main)",
    },
  },
  colors: {
    primary: {
      main: "#282f7e",
      mainContrast: "#fff",
      "50": "#f7f7fa",
      "100": "#dedfeb",
      "200": "#c2c4db",
      "300": "#a0a4c6",
      "400": "#8e91bb",
      "500": "#7579ac",
      "600": "#5f659f",
      "700": "#484e91",
      "800": "#3a4089",
      "900": "#252b73",
    },

    secondary: {
      main: "#ffb607",
      mainContrast: "#000",
      "50": "#fff7e3",
      "100": "#ffdd8a",
      "200": "#ffb912",
      "300": "#d99b06",
      "400": "#c28a05",
      "500": "#a37504",
      "600": "#8a6204",
      "700": "#6f4f03",
      "800": "#5e4303",
      "900": "#443002",
    },
    tertiary: {
      main: "#B59A5F",
      mainContrast: "#fff",
      "50": "#f9f7f2",
      "100": "#e8dfcd",
      "200": "#d4c4a2",
      "300": "#bca36d",
      "400": "#ab915a",
      "500": "#907a4c",
      "600": "#796740",
      "700": "#615333",
      "800": "#52462b",
      "900": "#3b321f",
    },
    light: {
      main: "#F7F7F7",
      alt: "#F7F7F7",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#fafafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#adadad",
      "500": "#7f7f7f",
      "600": "#545454",
      "700": "#373737",
      "800": "#202020",
      "900": "#191919",
    },
    yellow: {
      "50": "#fffef9",
      "100": "#fffad8",
      "200": "#ffef84",
      "300": "#ffde0a",
      "400": "#e9cb06",
      "500": "#c0a705",
      "600": "#9a8604",
      "700": "#786803",
      "800": "#5a4e02",
      "900": "#4a4102",
    },
    green: {
      "50": "#effff7",
      "100": "#98ffcf",
      "200": "#07f585",
      "300": "#06da77",
      "400": "#05bf68",
      "500": "#05a459",
      "600": "#04884a",
      "700": "#036a3a",
      "800": "#02572f",
      "900": "#024827",
    },
    teal: {
      "50": "#e7feff",
      "100": "#80fbff",
      "200": "#07ecf3",
      "300": "#06d2d9",
      "400": "#05b3b9",
      "500": "#04989d",
      "600": "#047c80",
      "700": "#036063",
      "800": "#025053",
      "900": "#024244",
    },
    cyan: {
      "50": "#f0fcff",
      "100": "#bef2ff",
      "200": "#9debff",
      "300": "#75e3ff",
      "400": "#07c4f4",
      "500": "#06b4e0",
      "600": "#06a2ca",
      "700": "#0586a7",
      "800": "#046e89",
      "900": "#03566a",
    },
    blue: {
      "50": "#eff7ff",
      "100": "#c5e0ff",
      "200": "#9ac9ff",
      "300": "#69afff",
      "400": "#3795ff",
      "500": "#077afd",
      "600": "#0666d4",
      "700": "#044ea3",
      "800": "#044086",
      "900": "#03356d",
    },
    purple: {
      "50": "#f9f5ff",
      "100": "#e7d9ff",
      "200": "#d4bcff",
      "300": "#b991ff",
      "400": "#a672ff",
      "500": "#8944ff",
      "600": "#6f1bff",
      "700": "#5606e0",
      "800": "#4705ba",
      "900": "#36048d",
    },
    pink: {
      "50": "#fff5fa",
      "100": "#ffd6e9",
      "200": "#ffb3d7",
      "300": "#ff80bb",
      "400": "#ff52a3",
      "500": "#ee0773",
      "600": "#cd0663",
      "700": "#a80551",
      "800": "#850440",
      "900": "#640330",
    },
    red: {
      "50": "#fff5f5",
      "100": "#ffd7d5",
      "200": "#ffb2af",
      "300": "#ff7f7b",
      "400": "#ff5c57",
      "500": "#f90f07",
      "600": "#d30c06",
      "700": "#ac0a05",
      "800": "#920904",
      "900": "#6c0603",
    },
    orange: {
      "50": "#fffaf4",
      "100": "#ffead2",
      "200": "#ffd19c",
      "300": "#ffa947",
      "400": "#f48507",
      "500": "#d37306",
      "600": "#b26105",
      "700": "#8e4d04",
      "800": "#703d03",
      "900": "#5c3203",
    },
  },
  templates: [
    {
      type: "free-talk",
      titleKey: "dashboard.template.freeTalk.title",
      descriptionKey: "dashboard.template.freeTalk.description",
      backgroundColor: "brand.primary.main",
      buttonBackgroundColor: "#fff",
      borderColor: "#333",
      buttonTextColor: "#333",
      descriptionTextColor: "white",
    },
    {
      type: "role-play",
      titleKey: "dashboard.template.rolePlay.title",
      descriptionKey: "dashboard.template.rolePlay.description",
      backgroundColor: "brand.secondary.main",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "topic",
      titleKey: "dashboard.template.topics.title",
      descriptionKey: "dashboard.template.topics.description",
      backgroundColor: "#94b55f",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "quiz",
      titleKey: "dashboard.template.quiz.title",
      descriptionKey: "dashboard.template.quiz.description",
      backgroundColor: "#62929e",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
    {
      type: "case",
      titleKey: "dashboard.template.caseStudy.title",
      descriptionKey: "dashboard.template.caseStudy.description",
      backgroundColor: "brand.tertiary.main",
      buttonBackgroundColor: "#333",
      borderColor: "#333",
      buttonTextColor: "white",
      descriptionTextColor: "black",
    },
  ],
};
