const l = {
  "base.and": "og",
  "base.toc": "Vilkår & Betingelser",
  "base.privacyPolicy": "Privatlivspolitik",
  "base.createAccount": "Opret konto",
  "base.signin": "Log ind",
  "base.signup": "Tilmeld dig",
  "base.logOut": "Log ud",
  "authentication.description": "Log ind eller opret en konto",
  "invitation.wrongEmailTitle": "Allerede logget ind",
  "invitation.wrongEmailText":
    "Det ser ud til, at du allerede er logget ind på en anden konto. Log venligst ud og prøv igen.",
  "invitation.invalidLinkTitle": "Ugyldig invitation",
  "invitation.invalidLinkText": "Invitationens link er ugyldigt",
  "invitation.completeSignup": "Venligst afslut tilmeldingen til ",
  "invitation.completeSignupDescription":
    "En bekræftelses-email er blevet sendt til din adresse. Hvis du ikke ser den i din indbakke, skal du tjekke din spam-mappe.",
  "invitation.completeSignupButton": "Afslut tilmelding",
  "invitation.mustSignupTitle": "Du er blevet inviteret til ",
  "invitation.signInToAccept":
    "Log venligst ind for at acceptere invitationen. I den invitationsemail, du har modtaget, finder du en midlertidig adgangskode.",
  "invitation.acceptTermsOnSignup": "Når du logger ind, accepterer du automatisk vores",
  "invitation.accept": "Log ind for at acceptere invitationen",
  "invitation.failed":
    "Kunne ikke acceptere invitationen. Prøv venligst at logge ud og logge ind - eller bed administratoren om at invitere dig igen.",
  "invitation.accepted": "Invitation accepteret",
  "invitation.emailNotVerified": "Email ikke bekræftet. Venligst bekræft din email og prøv igen.",
  "country.venezuela": "Venezuela",
  "country.japan": "Japan",
  "country.algeria": "Algeriet",
  "country.france": "Frankrig",
  "country.spain": "Spanien",
  "country.china": "Kina",
  "country.usa": "USA",
  "country.england": "England",
  "country.brazil": "Brasilien",
  "country.italy": "Italien",
  "country.denmark": "Danmark",
  "head.title": "Talkio AI | Øv sprog med AI",
  "landing.title1": "Øv ",
  "landing.title2": "Sprog ",
  "landing.title3": "med AI ",
  "landing.subtitle1":
    "Den ultimative sprogtræningsapp, der bruger AI-teknologi til at hjælpe dig med at forbedre dine mundtlige færdigheder.",
  "landing.button.freeTrial": "7 dage gratis",
  "landing.creditCardNotice": "Kreditkort kræves - annuller når som helst",
  "landing.text.voiceControlTeam": "Fra teamet bag Voice Control for ChatGPT",
  "landing.text.trustedBy": "Troet af mere end 500.000 brugere",
  "landing.card.title.advancedAI": "Bygget med avanceret AI-teknologi",
  "landing.card.text.advancedAI":
    "Talkio AI er bygget med avanceret AI-teknologi og lader dig interagere med AI'en via stemme for at træne dine mundtlige færdigheder.",
  "landing.card.title.multipleLanguages": "Understøtter flere sprog",
  "landing.card.text.multipleLanguages":
    "Vi tilbyder et forskelligt udvalg af sprogoptioner, inklusive engelsk, kinesisk, fransk og flere andre sprog.",
  "landing.card.title.premiumVoice": "Premium stemmeteknologi",
  "landing.card.text.premiumVoice":
    "Talkio AI tilbyder premium stemmer og understøtter flere dialekter for de mest populære sprog. Med vores avancerede sprogteknologi kan du engager dig i autentiske samtaler og opnå færdigheder i de dialekter, der betyder mest for dig.",
  "landing.sectionTitle.meetTutors": "Mød AI-tutorene",
  "landing.sectionText.moreThanTutors":
    "Talkio AI har mere end 400 tutorer med unikke kunstige personligheder klar til at hjælpe dig på din sprogrejse.",
  "landing.text.andManyMore": "Og mange flere",
  "landing.text.learnWithAITutors": "Lær med AI-tutorer",
  "landing.text.wonderedAboutTutor":
    "Har du nogensinde undret dig over, hvordan det ville være at have en personlig sprogunderviser tilgængelig når som helst, hvor som helst? Hos Talkio AI gør vi denne drøm til virkelighed. Vores AI-tutorer er de perfekte ledsagere til at forbedre dine mundtlige færdigheder. Drevet af avanceret AI-teknologi efterligner de menneskelig interaktion og samtale og tilbyder en fængende og effektiv sprogindlæring oplevelse.",
  "landing.text.rememberLanguageLearning":
    "Husk, sprogindlæring er mere end blot grammatik og ordforråd; det handler om at udtrykke dig selv med selvtillid. Med Talkio AIs intelligente tutorer kan du tale, øve og nå dine sprogsmål!",
  "landing.sectionTitle.featureSpotlight": "Funktioner i fokus",
  "landing.sectionText.featureSpotlight":
    "Naviger i vores udvalg af funktioner, designet til at gøre din sprogindlæring til en medrivende og engagerende rejse.",
  "landing.text.safeEnvironment": "Sikker og støttende miljø",
  "landing.text.supportiveEnvironment":
    "Vores app giver et sikkert og støttende miljø for dig at øve at tale med selvtillid. Med Talkio AI kan du have meningsfulde samtaler med hjælp fra AI-teknologi, hvilket giver dig mulighed for at opnå flydende tale og udtrykke dig selv på det sprog, du lærer.",
  "landing.sectionTitle.insights": "Indsigter",
  "landing.sectionText.realStories": "Virkelige historier fra rigtige brugere af Talkio AI",
  "landing.sectionTitle.faq": "Ofte stillede spørgsmål",
  "landing.sectionText.faq": "Svar på hyppige spørgsmål om Talkio AI",
  "landing.sectionTitle.pricing": "Prisfastsættelse",
  "landing.sectionText.unlockPotential":
    "Lås op for dit sprogfærdigheder med Talkio. Din rejse mod mesterskab begynder nu.",
  "landing.sectionText.offersForPartners": "Vi tilbyder også planer for skoler og teams",
  "landing.imageAlt.landing": "Landing",
  "landing.imageAlt.laurelLeft": "Laurbær venstre",
  "landing.imageAlt.laurelRight": "Laurbær højre",
  "landing.imageAlt.speakingWithAI": "Kvinde der taler med AI",
  "testimonial.text1":
    "Jeg har personligt brugt Talkio AI til at forbedre mit spansk, og lad mig sige, det er en utrolig mulighed.",
  "testimonial.text2":
    "Talkio AI er et meget imponerende værktøj og bør inkluderes i hver sprogindkøbers værktøjskasse.",
  "testimonial.text3": "Dette værktøj Talkio AI blæser mit sind for sprogindlæring.",
  "testimonial.text4": "Talkio AI - det er et fantastisk produkt.",
  "feature.lifeLikeConversations": "Livagtige samtaler med AI-tutorer",
  "feature.pronunciationTool": "Udtaleøvelsesværktøj",
  "feature.detailedFeedback": "Detaljeret feedback på dine tale færdigheder",
  "feature.interactiveWordbook": "Interaktiv ordbog",
  "feature.supportsLanguages": "Understøtter 40+ sprog",
  "landing.text.voiceConversations": "Stemmesamtaler",
  "landing.text.voiceConversationsDescription":
    "Have engagerende stemmesamtaler med vores AI-tutorer",
  "landing.text.pronunciation": "Udtale",
  "landing.text.pronunciationDescription": "Vurder og øv din udtale med ord-for-ord feedback",
  "landing.text.feedback": "Feedback",
  "landing.text.feedbackDescription":
    "Få øjeblikkelig feedback på dine sprogfærdigheder, og tips til hvordan du kan forbedre",
  "landing.text.multipleLanguages": "Flere sprog og dialekter",
  "landing.text.multipleLanguagesDescription":
    "Vælg mellem 134 sprog og dialekter for at øve dine tale færdigheder",
  "landing.text.progress": "Fremskridt",
  "landing.text.progressDescription":
    "Spor dine fremskridt og få ugentlige rapporter om din sprogindlæringsrejse",
  "landing.text.wordbook": "Ordbog",
  "landing.text.wordbookDescription": "Gem og genbesøg ord lært under træning",
  "landing.text.streaks": "Streaks",
  "landing.text.streaksDescription":
    "Hold styr på dine fremskridt med streaks for at opbygge en vane med læring",
  "landing.text.crosstalk": "Crosstalk",
  "landing.text.crosstalkDescription":
    "Kommuniker med tutor i både dit modersmål og det sprog, du lærer",
  "landing.text.translations": "Oversættelser",
  "landing.text.translationsDescription":
    "Øjeblikkelige oversættelser til at støtte dig i dine samtaler",
  "landing.text.featureSpotlight": "Funktioner i fokus",
  "landing.text.featureSpotlightDescription":
    "Naviger i vores udvalg af funktioner, designet til at gøre din sprogindlæring til en medrivende og engagerende rejse",
  "feature.practiceSpeaking.title": "Øv at tale",
  "feature.practiceSpeaking.bullet1": "Livagtige samtaler med en AI-tutor",
  "feature.practiceSpeaking.bullet2": "Stemmes først interaktion",
  "feature.practiceSpeaking.bullet3": "Samtalehjælp",
  "feature.practiceSpeaking.bullet4": "Gentag & prøv igen",
  "feature.worldOfLanguages.title": "En verden af sprog",
  "feature.worldOfLanguages.bullet1": "Understøtter 40+ sprog",
  "feature.worldOfLanguages.bullet2": "Øjeblikkelige oversættelser",
  "feature.worldOfLanguages.bullet3": "Multisproget talegenkendelse",
  "feature.worldOfLanguages.bullet4": "Romanisering af skriftdialekter",
  "feature.actionableFeedback.title": "Handlingsorienteret feedback",
  "feature.actionableFeedback.bullet1": "Sætning scoring",
  "feature.actionableFeedback.bullet2": "Forbedringsstrategier",
  "feature.actionableFeedback.bullet3": "AI-forslag",
  "feature.actionableFeedback.bullet4": "Tale præcisionsscore",
  "feature.pronunciationPractice.title": "Udtale praksis",
  "feature.pronunciationPractice.bullet1": "Ord-for-ord score",
  "feature.pronunciationPractice.bullet2": "Udtale vurdering",
  "feature.pronunciationPractice.bullet3": "Nøjagtighed, fuldstændighed & flydende",
  "feature.pronunciationPractice.bullet4": "Gentag & prøv igen",
  "feature.interactiveWordbook.title": "Interaktiv ordbog",
  "feature.interactiveWordbook.bullet1": "Hold styr på de ord, du lærer",
  "feature.interactiveWordbook.bullet2": "Forstærket memorisering via tutor gentagelse",
  "feature.interactiveWordbook.bullet3": "Oversættelse & læse-højt",
  "feature.interactiveWordbook.bullet4": "Sætnings eksempler",
  "feature.button.startTrial": "Start din gratis prøveperiode",
  "faq.question1": "Er der en gratis plan?",
  "faq.answer1":
    "Nej, alle planer er betalt. Dog er der en 7-dages gratis prøveperiode, der kan annulleres når som helst. Ingen forpligtelser.",
  "faq.question2": "Hvem står bag Talkio AI?",
  "faq.answer2.part1": "Talkio AI er et produkt af",
  "faq.answer2.part2":
    "opført i EU. Aidia ApS overholder den generelle databeskyttelsesforordning (GDPR) for at levere en sikker oplevelse for alle brugere.",
  "faq.question3": "Er Talkio AI velegnet til begyndere?",
  "faq.answer3":
    "Talkio AI er bedst egnet til dem med en grundlæggende viden om et sprog, der ønsker at forbedre deres mundtlige færdigheder. For begyndere har vi udviklet en introduktionsguide for at komme dig i gang. Denne guide dækker grundlæggende aspekter af det valgte sprog og forbereder dig på at få fuldt udbytte af Talkio AI. Den er gratis og et fantastisk værktøj til lærere, der starter deres rejse. Tjek guiden og begynd at forbedre dine sprogfærdigheder effektivt.",
  "faq.button.gettingStartedGuide": "Prøv den GRATIS guide til at komme i gang",
  "faq.question4": "Er betaling sikker?",
  "faq.answer4":
    "Ja, vi bruger Stripe til økonomisk behandling. Vi gemmer ikke nogen af dine kreditkortoplysninger.",
  "faq.question5": "Hvor er mine data gemt?",
  "faq.answer5":
    "Dine data er gemt på servere i EU: Microsoft (stemmeservice og data), Auth0 (autentificering), Stripe (betalinger). Desuden bruger vi tjenester fra OpenAI, Anthropic og Groq til at drive AI'en. Denne data behandles i USA.",
  "faq.question6": "Kan jeg få en refundering?",
  "faq.answer6":
    "Den gratis prøveperiode kan annulleres når som helst; men hvis du glemmer at annullere prøven, kan vi refundere dig inden for 14 dage, forudsat at du ikke har brugt tjenesten efter prøveperiodens udløb. Kontakt os venligst på support@talkio.ai",
  "faq.question7": "Hvilke betalingsmetoder accepterer I?",
  "faq.answer7":
    "For abonnementsordninger accepterer vi alle større kreditkort og Paypal. For engangskøb accepterer vi også WeChat, Przelewy24, Giropay, Alipay og mere. Tilgængeligheden afhænger af din placering.",
  "faq.question8": "Har Talkio en mobilapp?",
  "faq.answer8":
    "Talkio er en progressiv webapp, hvilket betyder, at du kan installere den fra browseren uden at gå til App Store eller Google Play. På Android, mens du bruger Talkio i din browser, finder du en installationsknap efter tilmelding. På iOS skal du åbne Talkio i Safari, trykke på del-ikonet og vælge 'Tilføj til startskærm' for at installere den som en almindelig app på din telefon.",
  "faq.question9": "Hvilke browsere kan jeg bruge?",
  "faq.answer9":
    "Talkio AI fungerer i alle moderne browsere, inklusive Chrome, Firefox, Safari og Edge. Det fungerer også på mobile browsere på iPhone og Android.",
  "faq.question10": "Hvilke AI-modeller bruger I?",
  "faq.answer10":
    "Talkio AI bruger en blanding af forskellige AI-modeller fra OpenAI, Anthropic og Meta. Hver model har en specifik opgave, de excellerer i, og sammen skaber de en kraftfuld AI-tutor.",
  "faq.question11": "Hvordan adskiller Talkio AI sig fra Voice Control for ChatGPT?",
  "faq.answer11":
    "Talkio AI tilbyder en sammenhængende oplevelse med ChatGPT tilpasset sprogindlæring. Det tilbyder også flere funktioner, herunder udtalevurdering, premium stemmeteknologier, flersproget støtte, kuraterede træningsmaterialer, integrerede feedback og oversættelser.",
  "faq.question12": "Hvordan kan jeg kontakte dig?",
  "faq.answer12": "Du er meget velkommen til at skrive en email til hello@talkio.ai",
  "layout.serviceBar.text": "Stiftet i Danmark. Vi respekterer dit privatliv.",
  "layout.serviceBar.community":
    "Bliv en del af et verdensomspændende fællesskab af sprogindlærere",
  "layout.nav.pricing": "Priser",
  "layout.nav.faq": "FAQ",
  "layout.nav.languages": "Sprog",
  "layout.nav.blog": "Blog",
  "layout.nav.schools": "Til skoler",
  "layout.nav.teams": "Til teams",
  "layout.nav.affiliateProgram": "Affiliate program",
  "layout.nav.signIn": "Log ind",
  "layout.nav.goToApp": "Gå til app",
  "layout.nav.menu": "Menu",
  "layout.cta.description":
    "Den ultimative sprogtræningsapp, der bruger AI-teknologi til at hjælpe dig med at forbedre dine mundtlige færdigheder.",
  "layout.cta.button": "Prøv Talkio AI",
  "layout.footer.pages": "Sider",
  "layout.footer.learningHub": "Læringshub",
  "layout.footer.blog": "Blog",
  "layout.footer.about": "Om",
  "layout.footer.affiliateProgram": "Affiliate program",
  "layout.footer.schools": "Talkio for Skoler",
  "layout.footer.teams": "Talkio for Teams",
  "layout.footer.languageGuide": "Gratis sprogguide til begyndere",
  "layout.footer.termsConditions": "Vilkår & Betingelser",
  "layout.footer.privacyPolicy": "Privatlivspolitik",
  "layout.footer.logout": "Log ud",
  "layout.footer.login": "Log ind",
  "layout.footer.blogPosts": "Blogindlæg",
  "layout.footer.blogPost1": "Ny funktion på Talkio AI: Interaktiv sprogguide",
  "layout.footer.blogPost2": "Talkio integrerer tre nye brugerdefinerede GPT'er",
  "layout.footer.blogPost3":
    "Forbedre dine IELTS tale færdigheder med Talkio AI: En trin-for-trin guide",
  "layout.footer.blogPost4": "3 effektive måder at øve mundtlig engelsk på",
  "layout.footer.blogPost5": "Bestå dine engelsksprogede eksamener",
  "layout.footer.blogPost6": "Bliv mester i TOEFL Speaking med Talkio AI: Din essentielle guide",
  "layout.footer.contact": "Kontakt",
  "layout.footer.rights": "Alle rettigheder forbeholdes.",
  "layout.footer.illustrationCredits": "Frontpage illustrationer af vectorjuice",
  "pricing.description.perMonthBilledOnce": "pr. måned - faktureres en gang",
  "pricing.description.perMonthBilledQuarterly": "pr. måned - faktureres kvartalsvis",
  "pricing.description.perMonthBilledBiannually": "pr. måned - faktureres hver 6. måned",
  "pricing.description.perMonthBilledYearly": "pr. måned - faktureres årligt",
  "pricing.description.perMonth": "pr. måned",
  "pricing.button.buyNow": "Køb nu med 7-dages pengene-tilbage-garanti",
  "pricing.button.startFreeTrial": "Start din gratis prøveperiode",
  "pricing.features.refund": "7-dages fuld refundering - annuller når som helst",
  "pricing.features.freeTrial": "7-dages gratis prøveperiode - annuller når som helst",
  "pricing.features.supportsLanguages": "Understøtter 40+ sprog",
  "pricing.features.voiceConversations": "Livagtige stemmesamtaler",
  "pricing.features.instantFeedback": "Øjeblikkelig feedback",
  "pricing.features.weeklyProgressReport": "Ugentlig fremskridtsrapport",
  "pricing.features.pronunciationPractice": "Udtaleøvelse",
  "pricing.features.interactiveWordbook": "Interaktiv ordbog",
  "pricing.features.speechRecognition": "Flersproget talegenkendelse",
  "pricing.features.extraFeatures": "Rollespil, emner, quizzer & mere!",
  "accessRequest.pageNotExist": "404 Side eksisterer ikke.",
  "accessRequest.createAccountTitle": "Opret en konto for at komme i gang",
  "accessRequest.createAccountMessage":
    "For at deltage skal du først oprette en konto. Efter tilmelding kan du anmode om adgang til systemet.",
  "accessRequest.createAccountButton": "Opret din konto",
  "accessRequest.alreadyHaveAccount": "Har du allerede en konto?",
  "accessRequest.signIn": "Log ind",
  "accessRequest.grantedTitle": "Tillykke!",
  "accessRequest.grantedMessage":
    "Du har med succes fået adgang til {serviceName}. Klik nedenfor for at begynde at bruge appen.",
  "accessRequest.goToApp": "Gå til app",
  "accessRequest.accountExists": "Konto findes allerede",
  "accessRequest.accountExistsMessage":
    "Du er allerede medlem med denne konto. For at fortsætte skal du logge ud og tilmelde dig med en anden email.",
  "accessRequest.logOut": "Log ud",
  "accessRequest.requestAccessTitle": "Anmod om adgang for at fortsætte",
  "accessRequest.requestAccessMessage": "Din anmodning skal godkendes, før du kan få adgang.",
  "accessRequest.checkBackLater":
    "Tjek venligst tilbage senere for at se, om din adgang er blevet givet.",
  "accessRequest.submitRequest": "Indsende anmodning",
  "accessRequest.pending": "Adgangsanmodning afventer",
};

export default l;
