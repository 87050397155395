import { SiteConfig } from "../../types";

export const _config: SiteConfig = {
  isDefault: false,
  showTidio: false,
  serviceName: "Global Language Institute (DEMO)",
  appLogoPath: "/partner/gli/logo.jpeg",
  appLogoHeight: "120px",
  landingLogoPath: "/partner/gli/logo.jpeg",
  landingLogoHeight: "80px",
  favicon: "/partner/gli/icon.png",
  pwaIcons: [
    {
      src: "/partner/gli/icon.png",
      type: "image/png",
      sizes: "192x192",
    },
  ],
  overrideLanding: true,
  // uiLanguage: "pl-PL",
  adminEmail: "contact@gli.demo",
  adminPhoneNumber: "+45 31604966",
  whatsAppNumber: 4531604966,
  emailsSender: "info@lang-services.com",
  // customContent: {
  //   baseLanguage: "en-US",
  // },
  //emailInvitationHtml: invitationTemplate,
  emailInvitationSubject: "You have been invited to Global Language Institute (DEMO)",
  emailInvitationText: `
  You have been invited to Global Language Institute (DEMO) 🎉
    
  We have created a user for you with the following details:
  Email: {email}
  Password: {password}

  Follow this link to accept the invitation:
  {link}
  `,
  copyright: "Global Language Institute",
  // practiceLanguageLock: "en-US",
  // userLanguageLock: "pl-PL",
  showLanguageGuides: true,
  subscriptionDefaults: {
    currency: "usd",
    price: 10_000,
    characterLimit: 1_050_000,
    productId: "demo-1",
  },
  reviewLink: "https://business.google.com/reviews",
  bioPrompt: `
    You are a tutor at Global Language Institute, a language school specializing in teaching English to students of all ages. 
    Our mission is to provide high-quality language education that is engaging, effective, and accessible to all. 
    The school is led by professor Michael Smith, a seasoned educator with over 20 years of experience in the field.
    Our team of experienced teachers is dedicated to helping students achieve their language learning goals and develop the skills they need to succeed in today's globalized world. 
    We offer a wide range of courses and programs to meet the needs of students at every level, from beginner to advanced. 
    Whether you are looking to improve your English for work, travel, or personal enrichment, we have a program that is right for you. Join us today and take the first step towards fluency in English!  
  `,
  avatarExcludeList: [
    "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyouNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, AnaNeural)",
  ],
  siteColors: {
    special: {
      desktopSidebar: "#fff",
      desktopSidebarBorderColor: "#e2e8f0",
      desktopSidebarText: "#000",
      desktopSidebarTextHover: "#eee",
      chatAsideMenu: "#37393b",
      speakInputIdle: "var(--chakra-colors-brand-primary-600)",
      speakInputRecording: "var(--chakra-colors-brand-primary-900)",
    },
  },
  colors: {
    primary: {
      main: "#39cfc7",
      mainContrast: "#000",
      "50": "#ebfaf9",
      "100": "#aeebe8",
      "200": "#5ed8d1",
      "300": "#33b8b1",
      "400": "#2da49e",
      "500": "#39cfc7",
      "600": "#207570",
      "700": "#1a5e5a",
      "800": "#164f4c",
      "900": "#103937",
    },
    secondary: {
      main: "#40da9c",
      mainContrast: "#000",
      "50": "#ebfbf4",
      "100": "#a9eed3",
      "200": "#50dda4",
      "300": "#37bb86",
      "400": "#31a778",
      "500": "#298d65",
      "600": "#237755",
      "700": "#1c6044",
      "800": "#18513a",
      "900": "#113a2a",
    },
    tertiary: {
      main: "#39cfc7",
      mainContrast: "#000",
      "50": "#ebfaf9",
      "100": "#aeebe8",
      "200": "#5ed8d1",
      "300": "#33b8b1",
      "400": "#2da49e",
      "500": "#268b85",
      "600": "#207570",
      "700": "#1a5e5a",
      "800": "#164f4c",
      "900": "#103937",
    },
    light: {
      main: "#eee",
      alt: "#eee",
    },
    // https://palette.saas-ui.dev/
    gray: {
      "50": "#fafafa",
      "100": "#f1f1f1",
      "200": "#e7e7e7",
      "300": "#d4d4d4",
      "400": "#adadad",
      "500": "#7f7f7f",
      "600": "#545454",
      "700": "#373737",
      "800": "#202020",
      "900": "#191919",
    },
    teal: {
      "50": "#f3fbfc",
      "100": "#cbeff3",
      "200": "#9de0e7",
      "300": "#62cdd9",
      "400": "#34b0be",
      "500": "#2d96a2",
      "600": "#247a83",
      "700": "#1c5f66",
      "800": "#174f55",
      "900": "#134146",
    },
    cyan: {
      "50": "#f5fbfd",
      "100": "#d6edf5",
      "200": "#c4e5f1",
      "300": "#afdbec",
      "400": "#6fbfdc",
      "500": "#50b1d5",
      "600": "#37a0c7",
      "700": "#2d85a4",
      "800": "#256d87",
      "900": "#1d5468",
    },
    blue: {
      "50": "#f2f6fc",
      "100": "#d1dff4",
      "200": "#afc7ec",
      "300": "#8aade3",
      "400": "#6795da",
      "500": "#477fd2",
      "600": "#3369ba",
      "700": "#27508e",
      "800": "#204274",
      "900": "#1a365f",
    },
    purple: {
      "50": "#f9f6fd",
      "100": "#e6daf6",
      "200": "#d4beef",
      "300": "#b996e6",
      "400": "#a67adf",
      "500": "#8c54d5",
      "600": "#7939cd",
      "700": "#632ea8",
      "800": "#51268a",
      "900": "#3c1c67",
    },
    pink: {
      "50": "#fdf6f8",
      "100": "#f6dae5",
      "200": "#efbbd0",
      "300": "#e491b3",
      "400": "#dd729d",
      "500": "#d2467e",
      "600": "#bb346a",
      "700": "#992a56",
      "800": "#772143",
      "900": "#581832",
    },
    red: {
      "50": "#fdf6f5",
      "100": "#f5dad7",
      "200": "#edb9b4",
      "300": "#e29087",
      "400": "#dc786d",
      "500": "#d25446",
      "600": "#b94033",
      "700": "#953429",
      "800": "#7e2c23",
      "900": "#5c2019",
    },
    orange: {
      "50": "#fdfaf6",
      "100": "#f6ecdc",
      "200": "#edd6b3",
      "300": "#deb578",
      "400": "#d19741",
      "500": "#b68132",
      "600": "#9a6d2a",
      "700": "#7a5722",
      "800": "#60441b",
      "900": "#4f3816",
    },
    yellow: {
      "50": "#fefefc",
      "100": "#faf9ec",
      "200": "#f1efc7",
      "300": "#e7e29c",
      "400": "#d8cf5c",
      "500": "#b4ab31",
      "600": "#908928",
      "700": "#706b1f",
      "800": "#545017",
      "900": "#454213",
    },
    green: {
      "50": "#f6fdfa",
      "100": "#cdf3e4",
      "200": "#95e5c5",
      "300": "#50d59f",
      "400": "#34bc85",
      "500": "#2ca173",
      "600": "#25855f",
      "700": "#1d684a",
      "800": "#17553c",
      "900": "#134632",
    },
  },
};
