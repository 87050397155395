function embed() {
  return {
    __html: `
    <script>(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');</script>
    <script async src='https://r.wdfl.co/rw.js' data-rewardful='e5d882'></script>`,
  };
}

export function RewardfulTag() {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === "development") {
    return null;
  }

  return <div dangerouslySetInnerHTML={embed()} />;
}
