import { invitationTemplateFallback } from "./invitation-default-fallback-html";
import { invitationTemplateDefault } from "./invitation-default-html";
import { invitationTemplateKoFallback } from "./invitation-ko-fallback-html";
import { invitationTemplateKorean } from "./invitation-ko-html";

export const invitation =
  process.env.NEXT_PUBLIC_SERVER === "Korea" // || process.env.NODE_ENV === "development"
    ? {
        html: invitationTemplateKorean,
        subject: "Talkio AI에 초대되었습니다",
        fallbackHtml: invitationTemplateKoFallback,
        fallbackSubject: `Talkio AI에 초대되었습니다`,
        sender: "hello@talkio.ai",
      }
    : {
        html: invitationTemplateDefault,
        subject: "Welcome to Talkio AI",
        fallbackHtml: invitationTemplateFallback,
        fallbackSubject: `You have got an invitation!`,
        sender: "hello@talkio.ai",
      };
