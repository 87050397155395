import { _config as chengxueluConfig } from "./partner/chengxuelu.com/config";
import { _config as spracheundwissenConfig } from "./partner/spracheundwissen.com/config";
import { _config as clubedealemaoConfig } from "./partner/clubedealemao.com/config";
import { _config as punkifyConfig } from "./partner/punkify.com/config";
import { _config as englishbisaConfig } from "./partner/englishbisa.com/config";
import { _config as lingoprofConfig } from "./partner/lingoprof.com/config";
import { _config as frenchamiConfig } from "./partner/frenchami.com/config";
import { _config as gliConfig } from "./partner/gli.demo/config";
import { _config as elingwistaConfig } from "./partner/elingwista.com/config";
import { _config as berlitzConfig } from "./partner/berlitz.com/config";
import { _config as saySoConfig } from "./partner/say-so.pl/config";
import { _config as iliEduskyConfig } from "./partner/ili-edusky.pl/config";
import { _config as inicijativaConfig } from "./partner/inicijativa.biz/config";
import { _config as magyarIskolaConfig } from "./partner/magyar-iskola.hu/config";
import { _config as emAcademyConfig } from "./partner/emacademy.eu/config";
import { _config as leventenglishConfig } from "./partner/leventenglish.com/config";
import { _config as talkioLocalConfig } from "./partner/talkio.local/config";
import { _config as defaultConfig } from "./default/config";
import { SiteConfig } from "./types";

export const partnerConfigs = {
  localhost: talkioLocalConfig,
  "talkio.local": talkioLocalConfig,
  "chengxuelu.com": chengxueluConfig,
  "spracheundwissen.com": spracheundwissenConfig,
  "clubedealemao.com": clubedealemaoConfig,
  "punkify.com": punkifyConfig,
  "englishbisa.com": englishbisaConfig,
  "lingoprof.com": lingoprofConfig,
  "frenchami.com": frenchamiConfig,
  "talkio.education": gliConfig,
  "elingwista.com": elingwistaConfig,
  "say-so.pl": saySoConfig,
  "ili.ai.edusky.pl": iliEduskyConfig,
  "berlitz-platforms.io": berlitzConfig,
  "inicijativa.biz": inicijativaConfig,
  "magyar-iskola.hu": magyarIskolaConfig,
  "emacademy.eu": emAcademyConfig,
  "leventenglish.com": leventenglishConfig,
};

export type PartnerConfigId = keyof typeof partnerConfigs;

const partnerDomain = process.env.NEXT_PUBLIC_PARTNER_DOMAIN as
  | keyof typeof partnerConfigs
  | undefined;

export const siteConfig: SiteConfig = partnerDomain
  ? {
      ...defaultConfig,
      ...partnerConfigs[partnerDomain],
    }
  : defaultConfig;

if (process.env.NEXT_PUBLIC_IS_PARTNER === "true" && !siteConfig) {
  throw new Error(`Partner domain ${partnerDomain} is not configured in allConfigs`);
}
