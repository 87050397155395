import { SiteConfig } from "../types";
import { invitation } from "./invitation";

export const _config: SiteConfig = {
  isDefault: true,
  serviceName: "Talkio AI",
  appLogoPath: "/talkio-logo-white.svg",
  appLogoHeight: "22px",
  landingLogoPath: "/talkio-logo.svg",
  landingLogoHeight: "25px",
  favicon: "/favicon.png",
  adminEmail: "hello@talkio.ai",
  emailsSender: invitation.sender,
  copyright: "Aidia ApS",
  reviewLink: "https://senja.io/p/talkio/r/9XyUN8",
  showTidio: true,
  avatarExcludeList: [
    "Microsoft Server Speech Text to Speech Voice (zh-CN, XiaoyouNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-US, AnaNeural)",
    "Microsoft Server Speech Text to Speech Voice (en-GB, MaisieNeural)",
    "Microsoft Server Speech Text to Speech Voice (zh-CN, YunxiaNeural)",
  ],
  hubFacilitatorCanManage: true,
  showLanguageGuides: true,
  hideAsideMenuConversations: true,
  emailInvitationSubject: invitation.subject,
  emailInvitationHtml: invitation.html,
  emailInvitationFallbackSubject: invitation.fallbackSubject,
  emailInvitationFallbackHtml: invitation.fallbackHtml,
  templates: [
    {
      type: "free-talk",
      titleKey: "dashboard.template.freeTalk.title",
      descriptionKey: "dashboard.template.freeTalk.description",
      backgroundImagePath: "/templates/quiz.jpeg",
      buttonBackgroundColor: "brand.yellow.400",
      buttonTextColor: "black",
      borderColor: "transparent",
    },
    {
      type: "role-play",
      titleKey: "dashboard.template.rolePlay.title",
      descriptionKey: "dashboard.template.rolePlay.description",
      backgroundImagePath: "/templates/role-play.jpeg",
      buttonBackgroundColor: "brand.teal.300",
      buttonTextColor: "black",
      borderColor: "transparent",
    },
    {
      type: "topic",
      titleKey: "dashboard.template.topics.title",
      descriptionKey: "dashboard.template.topics.description",
      backgroundImagePath: "/templates/topics.jpeg",
      buttonBackgroundColor: "brand.purple.400",
      buttonTextColor: "black",
      borderColor: "transparent",
    },
    {
      type: "quiz",
      titleKey: "dashboard.template.quiz.title",
      descriptionKey: "dashboard.template.quiz.description",
      backgroundImagePath: "/templates/quiz.jpeg",
      buttonBackgroundColor: "brand.pink.300",
      buttonTextColor: "black",
      borderColor: "transparent",
    },

    {
      type: "case",
      titleKey: "dashboard.template.caseStudy.title",
      descriptionKey: "dashboard.template.caseStudy.description",
      backgroundImagePath: "/templates/cases.jpeg",
      buttonBackgroundColor: "brand.green.400",
      buttonTextColor: "black",
      borderColor: "transparent",
    },
  ],
};
