import { createContext, useContext, useRef } from "react";
import { TtsAudio } from "./use-tts-audio";
import { useSpeechToText } from "./use-speech-to-text";
import { useTextToSpeech } from "./use-text-to-speech";
import { useTextToSpeechBrowser } from "./use-text-to-speech-browser";
import { useVoicePreference } from "../../../hooks/user-voice-preference";
import { useSpeechRecognitionProvider } from "./use-speech-recognition-provider";
import { Tutor, TutorContext } from "../../../hooks/use-tutor";

export type VoiceControl = ReturnType<typeof useVoiceControl>;

export const VoiceControlContext = createContext<VoiceControl>({} as VoiceControl);

export function useVoiceControl(ttsAudio: TtsAudio, tutor: Tutor) {
  useSpeechRecognitionProvider();

  /**
   * Ref to the audio element used for text-to-speech.
   * We define it so that we can create it on a user interaction and route the audio afterwards.
   * This is to ensure that the audio is not blocked by the browser on iOS.
   */
  const ttsAudioElementRef = useRef<HTMLAudioElement>();

  const { shouldUseBrowserVoice, browserVoiceRef, browserVoice } = useVoicePreference();
  const textToSpeechBrowser = useTextToSpeechBrowser({
    browserVoiceRef,
    browserVoice,
  });
  const speechToText = useSpeechToText(ttsAudioElementRef);
  const textToSpeech = useTextToSpeech(ttsAudio, ttsAudioElementRef, tutor);

  return {
    readAloudMessage: shouldUseBrowserVoice
      ? textToSpeechBrowser.readAloudMessage
      : textToSpeech.readAloudMessage,

    isLoadingAudioForMessageId: shouldUseBrowserVoice
      ? textToSpeechBrowser.isLoadingAudioForMessageId
      : textToSpeech.isLoadingAudioForMessageId,

    isReadingLatest: shouldUseBrowserVoice
      ? textToSpeechBrowser.isReadingLatest
      : textToSpeech.isReadingLatest,

    startAiSpeak: shouldUseBrowserVoice
      ? textToSpeechBrowser.startAiSpeak
      : textToSpeech.startAiSpeak,

    stopAiSpeak: shouldUseBrowserVoice ? textToSpeechBrowser.stopAiSpeak : textToSpeech.stopAiSpeak,

    isRecording: speechToText.isRecording,
    recordingAudioPulse: speechToText.recordingAudioPulse,
    startSpeechRecognition: speechToText.startSpeechRecognition,
    stopSpeechRecognition: speechToText.stopSpeechRecognition,
    resetRecognitions: speechToText.resetRecognitions,
    vadStatus: speechToText.vadStatus,
    transcript: speechToText.transcript,
    hasPulse: speechToText.hasPulse,
  };
}
